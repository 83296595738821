/* Remove transition delays */
.transition-none {
    transition: none !important;
  }
  
  .scrollbar {
    scrollbar-width: 2px !important;
  }
  
  .scrollbar::-webkit-scrollbar {
    width: 0.5rem; 
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #CBD5E1; 
    border-radius: 0.25rem;       
    min-height: 1.56rem; 
  }
  