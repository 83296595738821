@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '../node_modules/@demandscience/ui/src/inputs/Slider.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }

  .ring {
    @apply outline-none ring-2 ring-offset-2;
  }
  .theme-primary {
    @apply bg-primary-400 hover:bg-primary-500 focus:ring ring-primary-400;
  }
  .theme-secondary {
    @apply bg-secondary-400 hover:bg-secondary-500 focus:ring ring-secondary-400;
  }
  .theme-success {
    @apply bg-success-400 hover:bg-success-500 focus:ring ring-success-400;
  }
  .theme-error {
    @apply bg-error-400 hover:bg-error-500 focus:ring ring-error-400;
  }

  .ellipsis {
    @apply whitespace-nowrap overflow-hidden text-ellipsis;
  }

  .shadow-r {
    box-shadow: 6px 0 5px -5px #cbd5e1;
  }
}


.reflex-container.reflex-container.vertical > .reflex-splitter {
  @apply w-[3px] border-white bg-gray-200 z-0;
}

/* arrow styles for component/Tooltip  */
[data-popper-arrow="true"],
[data-popper-arrow="true"]::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

[data-popper-arrow="true"] {
  visibility: hidden;
}

[data-popper-arrow="true"]::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

[data-popper-placement^='top'] > [data-popper-arrow="true"] {
  bottom: -4px;
}

[data-popper-placement^='bottom'] > [data-popper-arrow="true"] {
  top: -4px;
}

[data-popper-placement^='left'] > [data-popper-arrow="true"] {
  right: -4px;
}

[data-popper-placement^='right'] > [data-popper-arrow="true"] {
  left: -4px;
}

.chat-bubble-ai {
  background-color:#E6F8F1;
  padding:16px 28px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display:inline-block;
}

.chat-bubble-me {
  background-color:#FFF3CF;
  padding:16px 28px;
  border-radius: 20px;
  border-bottom-right-radius: 2px;
  display:inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96 ;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#6CAD96;
  }
  28% {
    transform: translateY(-7px);
    background-color:#9ECAB9;
  }
  44% {
    transform: translateY(0px);
    background-color: #B5D9CB;
  }
}
.custom-box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  opacity: 1;
  border-color: #CBD5E1!important;
  position: absolute;
  top: 10px;
  left: 20px;
  background-color: white;
  border: 1px solid;
  width: 269px;
  height: 300px;
  padding: 16px;
  border-radius: 6px; 
}
